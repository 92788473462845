<template>
  <div class="shucang">
  <!--  <signed ref="signed" />  -->
 <van-nav-bar
   title="数藏图鉴"
   left-arrow
   @click-left="onClickLeft"
 />
    <div class="padded-l-10 padded-r-10" style="height: 190px">
      <shucangimg />
    </div>

    <!-- <van-tabs :active="tabsActive" type="card" color="#409eff">
      <van-tab title="股权" name="1">
        <List :cardType="tabsActive" :list="stockList"></List>
      </van-tab>
      <van-tab title="基金" name="2">
        <List :cardType="tabsActive" :list="fundList"></List>
      </van-tab>
    </van-tabs> -->

  </div>
</template>

<script>
import shucangimg from '@/components/shucangimg'
import Noticebar from '@/components/Noticebar'
import Signed from '@/components/Signed'
// import List from './components/List'
import { getWebConfig, getProductList, getMessageList } from '@/utils/api.js'
export default {
  name: 'shucang',
  components: {
    shucangimg,
    Noticebar,
    Signed
  },
  data() {
    return {
      show: false,
      config: {},
      tabsActive: '1',
      stockList: [],
      fundList: [],
      msgList: []
    }
  },
  created() {
    this.getConfig()
    // this.getStock()
    // this.getFund()
    this.getMsg()
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    getConfig() {
      getWebConfig()
        .then((res) => {
          this.config = res.data
          const notice = localStorage.getItem('notice')
          if (notice === 'open') {
            if (res.data.noticeSwitch === 0) {
              setTimeout(() => {
                this.openNotice()
              }, 300)
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    openNotice() {
      this.$dialog
        .alert({
          title: '通知',
          message: this.config.noticeContent,
          theme: 'round-button'
        })
        .then(() => {
          localStorage.setItem('notice', 'close')
        })
    },
    goTo(to) {
      this.$router.push(to)
    },
    dialog() {
      this.$toast('维护中')
    },
    // 获取股权列表
    getStock() {
      getProductList(1).then((res) => {
        this.stockList = res.data
      })
    },
    // 获取基金列表
    getFund() {
      getProductList(2).then((res) => {
        this.fundList = res.data
      })
    },
    // 获取消息列表
    getMsg() {
      getMessageList().then((res) => {
        this.msgList = res.data.splice(0, 5)

        let type = localStorage.getItem('hometype')
        if (type === 'login' && Number(this.msgList[0].status) === 0) {
          this.showDialogTip()
          localStorage.removeItem('hometype')
        }
      })
    },
    showDialogTip() {
      let msg = this.msgList[0]
      this.$dialog
        .alert({
          title: msg.title,
          message: msg.content,
          theme: 'round-button'
        })
        .then(() => {})
    }
  }
}
</script>
<style scoped lang="scss">
.shucang {
  background: url(~@/assets/images/bg.png) center no-repeat;
  background-size: 100% 100%;
  /*height: 100vh;*/
  font-size: 14px;
  min-height: 100%;
  .cate {
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    width: 100px;
    background: #fff;
    margin: 10px 6px;
    padding: 10px 5px;
    img {
      width: 24px;
      height: 25px;
    }
  }
}
.qwe {
  width: 100%;
  height: 375px;
  background: #ff6700;
}
</style>
